import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import { updateProductOrder } from 'API/ProductService';
import ProductItem from './ProductItem';
import NewProductForm from './NewProductForm';
import { StrictModeDroppable } from './StrictModeDroppable'; // Импортируем StrictModeDroppable
import './ProductList.scss'; // Добавляем новый файл стилей

const ProductList = () => {
  const dispatch = useDispatch();
  const id = useSelector((state) => state.types);
  const products = useSelector((state) => state.product.data);
  let meals = [];
  let CatagoryName;

  // Проверка наличия данных перед фильтрацией
  if (products && products.length > 0) {
    const product = products.find((e) => e.id === id);
    if (product) {
      meals = product.list;
      CatagoryName = product.name;
    }
  }

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedItems = Array.from(meals);
    const [removed] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, removed);

    // Выровнять нумерацию order перед отправкой
    const updatedItems = reorderedItems.map((item, index) => ({
      ...item,
      order: index,
    }));

    dispatch(updateProductOrder(updatedItems));
  };

  return (
    <div className="meals-container">
      <h3>Добавить продукт</h3>
      <NewProductForm />
      <h1>{CatagoryName}</h1>
      <DragDropContext onDragEnd={onDragEnd}>
        <StrictModeDroppable droppableId="products">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {meals.map((prod, index) => (
                <Draggable key={prod.id} draggableId={`${prod.id}`} index={index}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <ProductItem key={prod.id} {...prod} />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </StrictModeDroppable>
      </DragDropContext>
    </div>
  );
};

export default ProductList;
