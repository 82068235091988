import { addNewProduct } from 'API/ProductService';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './FormStyles.scss'; // Добавим общий файл стилей для форм

const NewProductForm = () => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [size, setSize] = useState('');
  const [file, setFile] = useState({
    image_file: null,
    image_preview: '',
  });
  const [error, setError] = useState('');
  const dispatch = useDispatch();
  const id = useSelector((state) => state.types);

  const handleAction = () => {
    if (!name.trim()) {
      setError('Название продукта не может быть пустым');
      return;
    }
    if (!price) {
      setError('Цена продукта не может быть пустой');
      return;
    }
    setError('');
    dispatch(addNewProduct({ name, file, id, price, description, size }));
    setName('');
    setDescription('');
    setPrice('');
    setSize('');
    setFile({ image_file: null, image_preview: '' });
  };

  function handleChange(e) {
    const image_as_base64 = URL.createObjectURL(e.target.files[0]);
    const image_as_files = e.target.files[0];
    setFile({
      image_preview: image_as_base64,
      image_file: image_as_files,
    });
  }

  return (
    <label className="form-label">
      <input
        className="form-input"
        placeholder="Name product"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <textarea
        className="form-textarea"
        placeholder="Description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />
      <input
        className="form-input"
        placeholder="Price product"
        value={price}
        onChange={(e) => setPrice(e.target.value)}
      />
      <input
        className="form-input"
        placeholder="Size"
        value={size}
        onChange={(e) => setSize(e.target.value)}
      />
      <input className="form-input" type="file" onChange={handleChange} />
      {file.image_preview && <img src={file.image_preview} alt="Preview" className="form-image-preview" />}
      <button className="form-button" onClick={handleAction}>Add</button>
      {error && <div className="error-message">{error}</div>}
    </label>
  );
};

export default NewProductForm;
