// src/API/PromoService.js
import { createAsyncThunk } from '@reduxjs/toolkit';
import { API_URL } from 'constants/constants';
import { getAuthHeaders } from 'utils/auth';

export const fetchPromo = createAsyncThunk('promo/fetchPromo', async () => {
  const res = await fetch(`${API_URL}promo`);
  const data = await res.json();
  return data;
});

export const updatePromo = createAsyncThunk(
  'promo/updatePromo',
  async ({ content, showPromoButton }, { rejectWithValue }) => {
    try {
      const response = await fetch(`${API_URL}promo`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthHeaders(),
        },
        body: JSON.stringify({ content, showPromoButton }),
      });

      if (!response.ok) {
        throw new Error("Can't update promo. Server error.");
      }

      return response.json();
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);
