// src/components/promo/PromoPage.js
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPromo } from 'API/PromoService';
import ReactMarkdown from 'react-markdown';
import { useNavigate, useLocation } from 'react-router-dom';
import goBack from '../../assets/images/arrow-left.svg';
import './PromoPage.scss';

function PromoPage() {
  const dispatch = useDispatch();
  const { content } = useSelector((state) => state.promo);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    dispatch(fetchPromo());
  }, [dispatch]);

  const navigateBack = () => {
    navigate(-1);
  };

  return (
    <div className="promo-page">
      <button
        className="goBack-wrapper"
        type="button"
        onClick={navigateBack}
      >
        <img
          className={location.pathname === '/' ? 'hide' : 'go-Back'}
          src={goBack}
          alt="go-back"
        />
      </button>
      <div className="promo-content">
        <ReactMarkdown>{content}</ReactMarkdown>
      </div>
    </div>
  );
}

export default PromoPage;
