import { useDispatch } from 'react-redux';
import React, { useState } from 'react';
import { API_URL } from 'constants/constants';
import { deleteCategory } from 'API/ProductService';
import { Link } from 'react-router-dom';
import { setType } from 'redux/typesReducer';
import ProductItem from './ProductItem';
import MyModal from './MyModal';
import './Category.scss';

const CategoryItem = ({ id, name, list, src, index }) => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);

  const confirmDelete = () => {
    if (list.length > 0) {
      alert('Невозможно удалить категорию, так как в ней есть продукты.');
      return;
    }

    if (window.confirm('Вы уверены, что хотите удалить эту категорию?')) {
      dispatch(deleteCategory(id));
    }
  };

  return (
    <>
      <div className="category-block">
        <Link to="/admin/product">
          <div
            role="button"
            className="catagory-container"
            onClick={() => dispatch(setType(id))}
            tabIndex={index}
          >
            <h1>{name}</h1>
            <img src={API_URL + src} alt={name} />
          </div>
        </Link>

        <button onClick={() => setVisible(true)}>изменить</button>
        <span className="span-del" onClick={confirmDelete}>
          &times;
        </span>
        <MyModal
          visible={visible}
          setVisible={setVisible}
          product={false}
          category={{ id, name, list, src, index }}
        />
      </div>
    </>
  );
};

export default CategoryItem;
