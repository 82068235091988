import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Route, Routes, useLocation } from 'react-router-dom';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import Tabs from 'components/tabs/Tabs';
import { updateCategoryOrder } from 'API/ProductService';
import Category from './Category';
import ProductList from './Product.list';
import { StrictModeDroppable } from './StrictModeDroppable'; // Импортируем StrictModeDroppable

function TypesAdmin() {
  const dispatch = useDispatch();
  const product = useSelector((state) => state.product.data);
  const products = useSelector((state) => state.product);
  const tabs = useSelector((state) => state.tabs);
  const location = useLocation();
  const [error, setError] = useState(null); // Добавляем состояние для ошибки
  const items = [
    { title: 'Кухня', index: 0, tab: '/admin' },
    { title: 'Бар', index: 1, tab: '#bar' },
  ];

  const isProductEditPage = location.pathname === '/admin/product';

  const onDragEnd = async (result) => {
    if (!result.destination) return;

    const filteredItems = product.filter((el) => el.index === tabs);
    const reorderedItems = Array.from(filteredItems);
    const [removed] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, removed);

    // Выровнять нумерацию order перед отправкой
    const updatedItems = reorderedItems.map((item, index) => {
      const baseOrder = item.index === 1 ? 100 : 0; // Начинаем с 100 для бара и с 0 для кухни
      return {
        ...item,
        order: baseOrder + index,
      };
    });

    // Обновить только те элементы, которые принадлежат текущей вкладке
    const otherItems = product.filter((el) => el.index !== tabs);
    const finalItems = [...updatedItems, ...otherItems];

    try {
      await dispatch(updateCategoryOrder(finalItems));
      setError(null); // Сбрасываем ошибку при успешном обновлении
    } catch (err) {
      setError('Ошибка при обновлении порядка категорий. Попробуйте снова.'); // Устанавливаем сообщение об ошибке
    }
  };

  return (
    <>
      {!isProductEditPage && <Tabs items={items} />}
      <div className="type-container">
        {!isProductEditPage && (
          <DragDropContext onDragEnd={onDragEnd}>
            <StrictModeDroppable droppableId="categories">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {product
                    .filter((el) => el.index === tabs)
                    .sort((a, b) => a.order - b.order) // Сортируем категории по полю order
                    .map((el, index) => (
                      <Draggable key={el.id} draggableId={`${el.id}`} index={index}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <Category key={el.id} {...el} />
                          </div>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </StrictModeDroppable>
          </DragDropContext>
        )}
      </div>
      {error && <div className="error-message">{error}</div>}
      {' '}
      {/* Отображаем сообщение об ошибке */}
      <Routes>
        <Route path="/product" element={<ProductList {...products} />} />
      </Routes>
    </>
  );
}

export default TypesAdmin;
