import { jwtDecode } from 'jwt-decode';

export const authenticate = (token) => {
  localStorage.setItem('authToken', token);
};

export const logout = () => {
  localStorage.removeItem('authToken');
};

export const isAuthenticated = () => {
  const token = localStorage.getItem('authToken');
  if (!token) {
    return false;
  }

  try {
    const { exp } = jwtDecode(token);
    if (Date.now() >= exp * 1000) {
      logout();
      return false;
    }
    return true;
  } catch (e) {
    return false;
  }
};

export const getAuthToken = () => localStorage.getItem('authToken'); // Возвращает токен authToken

export const getAuthHeaders = () => {
  const token = getAuthToken();
  if (!token) {
    throw new Error('No auth token found.');
  }
  return {
    Authorization: `Bearer ${token}`,
  };
};
