import { useDispatch } from 'react-redux';
import './App.scss';
import React, { useEffect } from 'react';
import { fetchProducts } from 'API/ProductService';
import { Route, Routes } from 'react-router-dom';
import StartPage from './components/startPage/StartPage';
import Menu from './components/menu/Menu';
import LoginPage from './components/Auth/LoginPage';
import AdminPage from './components/Admin/Admin';
import PromoPage from './components/promo/PromoPage'; // Импортируем PromoPage

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<StartPage />} />
        <Route path="/menu/*" element={<Menu />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/admin/*" element={<AdminPage />} />
        <Route path="/promo" element={<PromoPage />} />
        {' '}
        {/* Добавляем маршрут для PromoPage */}
      </Routes>
    </div>
  );
}

export default App;
