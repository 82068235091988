import { combineReducers, configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import productReducer from 'API/ProductService';
import mealReducer from './dataReduser';
import typeReducer from './typesReducer';
import tabsReducer from './tabsReducer';
import promoReducer from './promoSlice'; // Импортируем новый слайс

const rootReducer = combineReducers({
  meals: mealReducer,
  types: typeReducer,
  tabs: tabsReducer,
  product: productReducer,
  promo: promoReducer, // Добавляем новый слайс
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: ['getCountries//fulfilled', 'getDetials//fulfilled'],
    },
  }).concat(logger),
});

export default store;
