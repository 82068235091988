import React, { useEffect } from 'react';
import './Admin.scss';
import { useNavigate, Route, Routes, Link } from 'react-router-dom';
import { isAuthenticated, logout } from 'utils/auth';
import NewCategoryForm from 'components/AdminComponent/NewCategoryForm';
import TypesAdmin from 'components/AdminComponent/TypesAdmin';
import ProductList from 'components/AdminComponent/Product.list'; // Импортируем ProductList
import PromoEditorPage from './PromoEditorPage';

function Admin() {
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate('/login');
    }
  }, [navigate]);

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  return (
    <div className="admin-page">
      <h1>Admin Panel</h1>
      <button onClick={handleLogout} className="logout-button">Выйти</button>
      <div className="admin-navigation">
        <Link to="/admin">Главная</Link>
        <Link to="/admin/promo">Редактировать Promo</Link>
      </div>
      <Routes>
        <Route
          path="/"
          element={(
            <>
              <div className="form-container">
                <h3>Добавить категорию</h3>
                <NewCategoryForm />
              </div>
              <TypesAdmin />
            </>
          )}
        />
        <Route path="/promo" element={<PromoEditorPage />} />
        <Route path="/product" element={<ProductList />} />
        {' '}
        {/* Обновляем маршрут */}
      </Routes>
    </div>
  );
}

export default Admin;
