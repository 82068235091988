// src/components/Admin/PromoEditorPage.js
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPromo, updatePromo } from 'API/PromoService';
import MarkdownIt from 'markdown-it';
import MdEditor from 'react-markdown-editor-lite';
import 'react-markdown-editor-lite/lib/index.css';
import ReactMarkdown from 'react-markdown';
import './PromoEditorPage.scss';

const mdParser = new MarkdownIt();

function PromoEditorPage() {
  const dispatch = useDispatch();
  const { content, showPromoButton: initialShowPromoButton } = useSelector((state) => state.promo);
  const [promoContent, setPromoContent] = useState(content);
  const [showPromoButton, setShowPromoButton] = useState(initialShowPromoButton);

  useEffect(() => {
    dispatch(fetchPromo());
  }, [dispatch]);

  useEffect(() => {
    setPromoContent(content);
    setShowPromoButton(initialShowPromoButton);
  }, [content, initialShowPromoButton]);

  const handleEditorChange = ({ text }) => {
    setPromoContent(text);
  };

  const savePromoContent = () => {
    dispatch(updatePromo({ content: promoContent, showPromoButton }));
  };

  const handleCheckboxChange = () => {
    setShowPromoButton(!showPromoButton);
  };

  return (
    <div className="promo-editor-page">
      <h1>Редактировать Promo</h1>
      <MdEditor
        value={promoContent}
        style={{ height: '600px' }}
        renderHTML={(text) => mdParser.render(text)}
        onChange={handleEditorChange}
        view={{ menu: true, md: true, html: false }}

      />
      <div className="checkbox-container">
        <input
          type="checkbox"
          checked={showPromoButton}
          onChange={handleCheckboxChange}
        />
        <label>Отображать кнопку промо на главной</label>
      </div>
      <button onClick={savePromoContent} className="save-button">Сохранить</button>
      <h2>Предварительный просмотр</h2>

      <div className="preview">
        <div className="promo-content neon-content">
          <ReactMarkdown>{promoContent}</ReactMarkdown>
        </div>
      </div>
    </div>
  );
}

export default PromoEditorPage;
