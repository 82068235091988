import { editNewCategory } from 'API/ProductService';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import './FormStyles.scss'; // Добавим общий файл стилей для форм

const EditCategoryForm = ({ id, name, index, setVisible }) => {
  const [sname, setName] = useState(name);
  const [sindex, setIndex] = useState(index);
  const [sfile, setFile] = useState({
    image_file: null,
    image_preview: '',
  });
  const [error, setError] = useState('');
  const dispatch = useDispatch();

  const handleAction = async () => {
    if (!sname.trim()) {
      setError('Название категории не может быть пустым');
      return;
    }
    if (sindex === '' || sindex === null || sindex === undefined) {
      setError('Выберите индекс категории');
      return;
    }
    setError('');
    const resultAction = await dispatch(editNewCategory({ sname, sfile, id, sindex }));
    if (editNewCategory.fulfilled.match(resultAction)) {
      setVisible(false); // Закрываем модальное окно при успешном завершении
    } else {
      setError('Ошибка при обновлении категории');
    }
  };

  function handleChange(e) {
    const image_as_base64 = URL.createObjectURL(e.target.files[0]);
    const image_as_files = e.target.files[0];
    setFile({
      image_preview: image_as_base64,
      image_file: image_as_files,
    });
  }

  return (
    <label className="form-label">
      <input
        className="form-input"
        placeholder="Name category"
        value={sname}
        onChange={(e) => setName(e.target.value)}
      />
      <select
        className="form-input"
        value={sindex}
        onChange={(e) => setIndex(e.target.value)}
      >
        <option value="">Select Index</option>
        <option value="0">Кухня</option>
        <option value="1">Бар</option>
      </select>
      <input className="form-input" type="file" onChange={handleChange} />
      {sfile.image_preview && <img src={sfile.image_preview} alt="Preview" className="form-image-preview" />}
      <button className="form-button" onClick={handleAction}>Edit</button>
      {error && <div className="error-message">{error}</div>}
    </label>
  );
};

export default EditCategoryForm;
