// src/components/startPage/StartPage.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { fetchPromo } from 'API/PromoService';
import './StartPage.scss';
import Logo from './Logo';

function StartPage() {
  const [showButton, setShowButton] = useState(false);
  const dispatch = useDispatch();
  const { showPromoButton } = useSelector((state) => state.promo);

  useEffect(() => {
    dispatch(fetchPromo());
  }, [dispatch]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowButton(true);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="start-page">
      <div className="logo-container">
        <Logo />
      </div>
      <h1 />
      <Link
        to="/menu"
        className={`start-button ${showButton ? 'show' : ''}`}
      >
        Меню
      </Link>
      {showPromoButton && (
        <Link
          to="/promo"
          className={`promo-button ${showButton ? 'show' : ''}`}
        >
          Promo
        </Link>
      )}
    </div>
  );
}

export default StartPage;
