import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API_URL } from 'constants/constants';
import { authenticate } from '../../utils/auth';
import './LoginPage.scss';

const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      // Выполните запрос на авторизацию к вашему API
      const response = await fetch(`${API_URL}auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      if (response.ok) {
        const { access_token } = await response.json(); // Изменено на access_token
        authenticate(access_token); // Сохраните токен в localStorage
        navigate('/admin'); // Перенаправьте на страницу админки
      } else {
        // Обработайте ошибку авторизации
        const errorData = await response.json();
        setError(errorData.message || 'Неизвестная ошибка');
      }
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
      setError('Ошибка при выполнении запроса');
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <h1>Страница входа</h1>
        <input
          type="text"
          placeholder="Имя пользователя"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          type="password"
          placeholder="Пароль"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button onClick={handleLogin}>Войти</button>
        {error && <div className="error-message">{error}</div>}
      </div>
    </div>
  );
};

export default LoginPage;
