// src/redux/promoSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { fetchPromo, updatePromo } from 'API/PromoService';

const promoSlice = createSlice({
  name: 'promo',
  initialState: {
    content: '',
    showPromoButton: false,
    isLoading: false,
    isError: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPromo.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchPromo.fulfilled, (state, action) => {
      state.isLoading = false;
      state.content = action.payload.content;
      state.showPromoButton = action.payload.showPromoButton;
    });
    builder.addCase(fetchPromo.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });
    builder.addCase(updatePromo.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updatePromo.fulfilled, (state, action) => {
      state.isLoading = false;
      state.content = action.payload.content;
      state.showPromoButton = action.payload.showPromoButton;
    });
    builder.addCase(updatePromo.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });
  },
});

export default promoSlice.reducer;
