import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import { API_URL } from 'constants/constants';
import { getAuthHeaders } from 'utils/auth'; // Импорт функции для получения заголовков авторизации

export const fetchProducts = createAsyncThunk('fetchProducts', async () => {
  const res = await fetch(`${API_URL}products/find-category`);
  const data = await res.json();
  if (!Array.isArray(data)) {
    throw new Error('Fetched data is not an array');
  }
  return data;
});
export const searchProducts = createAsyncThunk(
  'searchProducts',
  async (dataImput) => {
    const res = await fetch(`${API_URL}products/find-product?name=${dataImput}`);
    if (!res.ok) {
      throw new Error("Can't add task. Server error.");
    }
    return res?.json();
  },
);

export const addNewCategory = createAsyncThunk(
  'category/addNewCategory',
  async (dataImput, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      const maxSize = 5 * 1024 * 1024; // 5MB, например

      if (dataImput.file.image_file.size > maxSize) {
        throw new Error('Файл слишком большой. Максимальный размер файла: 5MB.');
      }

      formData.append('file', dataImput.file.image_file);
      formData.append('name', dataImput.name);
      formData.append('index', dataImput.index);

      const response = await fetch(`${API_URL}products/create-cat`, {
        method: 'POST',
        body: formData,
        headers: {
          ...getAuthHeaders(), // Добавьте заголовки авторизации, если они необходимы
        },
      });

      if (!response.ok) {
        throw new Error("Can't add category. Server error.");
      }

      const data = await response.json();

      dispatch(addCategory(data));

      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const addNewProduct = createAsyncThunk(
  'products/addNewProduct',
  async (dataImput, { rejectWithValue, dispatch }) => {
    try {
      const formData = new FormData();
      formData.append('file', dataImput.file.image_file);
      formData.append('name', dataImput.name);
      formData.append('category', dataImput.id);
      formData.append('description', dataImput.description);
      formData.append('price', dataImput.price);
      formData.append('size', dataImput.size);
      console.log(dataImput);
      for (const pair of formData.entries()) {
        console.log(`${pair[0]}: ${pair[1]}`);
      }
      const response = await fetch(`${API_URL}products/create-prod`, {
        method: 'POST',
        headers: {
          ...getAuthHeaders(),
        },
        body: formData,

      });

      if (!response.ok) {
        throw new Error("Can't add task. Server error.");
      }

      const data = await response.json();
      dispatch(addProduct(data));
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const editNewProduct = createAsyncThunk(
  'products/editNewProduct',
  async (dataInput, { rejectWithValue, dispatch }) => {
    try {
      const formData = new FormData();
      if (dataInput.sfile) {
        formData.append('file', dataInput.sfile.image_file);
      }
      formData.append('name', dataInput.sname);
      formData.append('description', dataInput.sdescription);
      formData.append('price', dataInput.sprice);
      formData.append('size', dataInput.ssize);

      const response = await fetch(`${API_URL}products/prod/${dataInput.id}`, {
        method: 'PUT',
        headers: {
          ...getAuthHeaders(),
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Can't update product. Server error.");
      }

      const updatedProduct = await response.json();

      dispatch(updateProductInCategory(updatedProduct));

      return updatedProduct;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const editNewCategory = createAsyncThunk(
  'category/editNewCategory',
  async (dataImput, { rejectWithValue, dispatch }) => {
    try {
      const formData = new FormData();
      if (dataImput.sfile && dataImput.sfile.image_file) {
        formData.append('file', dataImput.sfile.image_file);
      }
      formData.append('name', dataImput.sname);
      formData.append('index', dataImput.sindex);

      const response = await fetch(`${API_URL}products/cat/${dataImput.id}`, {
        method: 'PUT',
        headers: {
          ...getAuthHeaders(),
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Can't update category. Server error.");
      }

      const data = await response.json();
      dispatch(updateCategory(data)); // Обновляем состояние в Redux
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const deleteProduct = createAsyncThunk(
  'product/deleteProduct',
  async ({ id, idCat }, { rejectWithValue, dispatch }) => {
    try {
      const response = await fetch(`${API_URL}products/prod/${id}`, {
        method: 'DELETE',
        headers: {
          ...getAuthHeaders(),
        },
      });

      if (!response.ok) {
        throw new Error("Can't delete task. Server error.");
      }

      dispatch(removeProduct({ id, idCat }));
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const deleteCategory = createAsyncThunk(
  'category/deleteCategory',
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await fetch(`${API_URL}products/cat/${id}`, {
        method: 'DELETE',
        headers: {
          ...getAuthHeaders(),
        },
      });

      if (!response.ok) {
        throw new Error("Can't delete task. Server error.");
      }

      dispatch(removeCategory({ id }));
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const updateCategoryOrder = createAsyncThunk(
  'category/updateCategoryOrder',
  async (categories, { rejectWithValue, dispatch }) => {
    try {
      const response = await fetch(`${API_URL}products/update-category-order`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthHeaders(),
        },
        body: JSON.stringify(categories),
      });

      if (!response.ok) {
        throw new Error("Can't update category order. Server error.");
      }

      const data = await response.json();
      console.log('Updated category order:', data); // Логируем ответ сервера
      return data;
    } catch (error) {
      console.error('Error updating category order:', error.message); // Логируем ошибку
      return rejectWithValue(error.message);
    }
  },
);

export const updateProductOrder = createAsyncThunk(
  'products/updateProductOrder',
  async (products, { rejectWithValue }) => {
    try {
      const response = await fetch(`${API_URL}products/update-product-order`, {
        method: 'PUT',
        headers: {
          ...getAuthHeaders(),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(products),
      });

      if (!response.ok) {
        throw new Error("Can't update product order. Server error.");
      }

      const data = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

const productSlice = createSlice({
  name: 'product',
  initialState: {
    isLoading: false,
    data: [], // Убедитесь, что это инициализировано как массив
    isError: false,
    search: [],
  },
  reducers: {
    addProduct(state, action) {
      const cat = state.data.find((cat) => cat.id == action.payload.category);
      cat.list.push(action.payload);
    },
    addCategory(state, action) {
      console.log(action.payload);
      state.data.push(action.payload);
    },
    searchProduct(state, action) {
      return action.payload;
    },
    toggleComplete(state, action) {
      const toggledProduct = state.data.product.find(
        (product) => product.id === action.payload.id,
      );
      toggledProduct.completed = !toggledProduct.completed;
    },
    removeProduct(state, action) {
      const cat = state.data.find((cat) => cat.id === action.payload.idCat);

      if (cat) {
        cat.list = cat.list.filter((prod) => prod.id != action.payload.id);
      }
    },
    removeCategory(state, action) {
      state.data = state.data.filter(
        (product) => product.id !== action.payload.id,
      );
    },
    updateCategory(state, action) {
      const index = state.data.findIndex((cat) => cat.id === action.payload.id);
      if (index !== -1) {
        state.data[index] = {
          ...action.payload,
          index: parseInt(action.payload.index, 10),
        };
      }
    },
    updateProductInCategory(state, action) {
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProducts.pending, (state, action) => {
      state.isLoading = 'loading';
    });
    builder.addCase(fetchProducts.fulfilled, (state, action) => {
      state.isLoading = 'succeeded';
      state.data = action.payload;
    });
    builder.addCase(fetchProducts.rejected, (state, action) => {
      state.isError = 'failed';
    });
    builder.addCase(searchProducts.pending, (state, action) => {
      state.isLoading = 'loading';
    });
    builder.addCase(searchProducts.fulfilled, (state, action) => {
      state.isLoading = 'succeeded';
      console.log('filll', action.payload);
      state.search = action.payload;
    });
    builder.addCase(searchProducts.rejected, (state, action) => {
      state.isError = 'failed';
    });
    builder.addCase(updateCategoryOrder.fulfilled, (state, action) => {
      state.data = action.payload; // Убедитесь, что это массив категорий
    });
    builder.addCase(updateProductOrder.fulfilled, (state, action) => {
      state.data = action.payload; // Убедитесь, что это массив категорий
    });
  },
});

const {
  addProduct,
  addCategory,
  removeProduct,
  updateCategory,
  removeCategory,
  updateProductInCategory,
} = productSlice.actions;
export default productSlice.reducer;
