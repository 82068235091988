import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addNewCategory } from 'API/ProductService';
import './FormStyles.scss'; // Добавим общий файл стилей для форм

const NewCategoryForm = () => {
  const [name, setName] = useState('');
  const [index, setIndex] = useState('');
  const [file, setFile] = useState({
    image_file: null,
    image_preview: '',
  });
  const [error, setError] = useState('');
  const dispatch = useDispatch();

  const handleFileChange = (e) => {
    const image_as_base64 = URL.createObjectURL(e.target.files[0]);
    const image_as_files = e.target.files[0];
    const maxSize = 5 * 1024 * 1024; // 5MB, например

    if (image_as_files.size > maxSize) {
      setError('Файл слишком большой. Максимальный размер файла: 5MB.');
      return;
    }

    setFile({
      image_preview: image_as_base64,
      image_file: image_as_files,
    });
    setError('');
  };

  const handleAction = () => {
    if (!name.trim()) {
      setError('Название категории не может быть пустым');
      return;
    }
    if (!index) {
      setError('Выберите индекс категории');
      return;
    }
    if (!file.image_file) {
      setError('Пожалуйста, выберите файл изображения');
      return;
    }
    setError('');
    dispatch(addNewCategory({ name, file, index }));
    setName('');
    setIndex('');
    setFile({ image_file: null, image_preview: '' });
  };

  return (
    <label className="form-label">
      <input
        className="form-input"
        placeholder="Name category"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <select
        className="form-input"
        value={index}
        onChange={(e) => setIndex(e.target.value)}
      >
        <option value="">Вкладка</option>
        <option value="0">Кухня</option>
        <option value="1">Бар</option>
      </select>
      <input className="form-input" type="file" onChange={handleFileChange} />
      {file.image_preview && <img src={file.image_preview} alt="Preview" className="form-image-preview" />}
      <button className="form-button" onClick={handleAction}>Add</button>
      {error && <div className="error-message">{error}</div>}
    </label>
  );
};

export default NewCategoryForm;
