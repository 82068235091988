import React from 'react';
import './MyModal.scss';
import EditProductForm from './EditProductForm';
import EditCategoryForm from './EditCategoryForm';

const MyModal = ({ visible, setVisible, product, category }) => (
  <div
    onClick={() => setVisible(false)}
    style={{ backgroundColor: 'transparent' }}
    className={visible ? 'myModal active' : 'myModal'}
  >
    <div className="myModalContent" onClick={(e) => e.stopPropagation()}>
      {category ? (
        <EditCategoryForm {...category} setVisible={setVisible} />
      ) : (
        <EditProductForm {...product} setVisible={setVisible} />
      )}
    </div>
  </div>
);

export default MyModal;
