import React from 'react';
import './Types.scss';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Tabs from 'components/tabs/Tabs';

import Catagory from '../catagory/Catagory';

function Types() {
  const product = useSelector((state) => state.product.data);
  const tabs = useSelector((state) => state.tabs);
  const location = useLocation();
  const items = [
    { title: 'Кухня', index: 0, tab: '/menu' },
    { title: 'Бар', index: 1, tab: '#bar' },
  ];

  return (
    <>
      <Tabs items={items} />
      <div className="type-container">
        {location.pathname === '/menu'
          && product.map((el) => (el.index === tabs ? <Catagory catagory={el} key={el.name} /> : ''))}
      </div>
    </>
  );
}

export default Types;
