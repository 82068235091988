import { editNewProduct } from 'API/ProductService';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import './FormStyles.scss'; // Добавим общий файл стилей для форм

const EditProductForm = ({ id, name, src, description, price, size, setVisible }) => {
  const [sname, setSname] = useState(name);
  const [sdescription, setDescription] = useState(description);
  const [sprice, setPrice] = useState(price);
  const [ssize, setSize] = useState(size);
  const [sfile, setFile] = useState({
    image_file: null,
    image_preview: '',
  });
  const [error, setError] = useState('');

  const dispatch = useDispatch();
  const handleAction = async () => {
    if (!sname.trim()) {
      setError('Название продукта не может быть пустым');
      return;
    }
    if (!sprice) {
      setError('Цена продукта не может быть пустой');
      return;
    }
    setError('');
    const resultAction = await dispatch(
      editNewProduct({ sname, sfile, id, sprice, sdescription, ssize }),
    );
    if (editNewProduct.fulfilled.match(resultAction)) {
      setVisible(false); // Закрываем модальное окно при успешном завершении
    } else {
      setError('Ошибка при обновлении продукта');
    }
  };

  function handleChange(e) {
    const image_as_base64 = URL.createObjectURL(e.target.files[0]);
    const image_as_files = e.target.files[0];
    setFile({
      image_preview: image_as_base64,
      image_file: image_as_files,
    });
  }

  return (
    <label className="form-label">
      <input className="form-input" value={sname} onChange={(e) => setSname(e.target.value)} />
      <textarea
        className="form-textarea"
        name="textarea"
        onChange={(e) => setDescription(e.target.value)}
        defaultValue={sdescription || ''}
      />
      <input className="form-input" value={sprice} onChange={(e) => setPrice(e.target.value)} />
      <input className="form-input" value={ssize} onChange={(e) => setSize(e.target.value)} />
      <input className="form-input" type="file" onChange={handleChange} />
      {sfile.image_preview && <img src={sfile.image_preview} alt="Preview" className="form-image-preview" />}
      <button className="form-button" onClick={handleAction}>Edit</button>
      {error && <div className="error-message">{error}</div>}
    </label>
  );
};

export default EditProductForm;
